<template>
    <div>
        <div class="is-pulled-right"><router-link to="/tickets/add-ticket"><button class="button is-success">Add New Ticket</button></router-link></div>
        <h2 class="title is-size-4">My Tickets</h2>
        <div class="notification is-warning" v-if="loading == true">
            Loading tickets ...
        </div>
        <div class="notification is-warning" v-if="loading == false && tickets.length == 0">
            No open tickets can be found
        </div>

        <div class="is-pulled-right" v-if="loading == false" style="display:none">
            <input type="text" class="input" v-model="search" placeholder="Search ..."/>
        </div>

        <table class="table is-fullwidth is-striped is-hoverable" v-if="loading == false && tickets.length > 0">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th>Description</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in tickets" :key="row.id">
                    <td>{{ row.date }}</td>
                    <td>{{ row.ticket_type_name }}</td>
                    <td>{{ row.status }}</td>
                    <td>{{ row.description }}</td>
                    <td><button class="button is-small is-danger" style="display:none">Cancel</button></td>
                    
                </tr>
               
            </tbody>

        </table>

        
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

export default {
    
      name: 'MyTickets',
        mixins: [MemberMixin],
        data: function () {
            return {
                   search: '',
                   tickets: [],
                   loading: false,
                   accessToken: ''
                  
            }
        },
        methods: {
            async getTickets() {
                this.loading=true
                UtilityService.getSimpleApiData(this.accessToken, "tickets?open=1")
                .then(
                    (tickets => {
                        this.$set(this, "tickets", tickets);
                        this.loading=false
                    }).bind(this)
                );
            },
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getTickets();
                
            });
        
        },
        computed : {
            
        }
    }
</script>