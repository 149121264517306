<template>
    <div>
        
        <div v-if="loading == false ">
            <h4 class="title is-size-4">Add A New Ticket</h4>
            <form @submit.prevent="saveTicket()">
                
                
               
                <div class="field">
                    <label class="label">Description </label>
                    <div class="control " >
                        <textarea class="textarea" v-model="description" required ></textarea>
                        
                    </div>
                </div>


                <div class="field is-grouped" ><div class="control"><button type="submit" class="button is-success" :disabled="working==true">Submit Ticket</button></div></div>
            </form>
            


           
        </div>

        
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import TicketService from '@/services/TicketService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

export default {
    
      name: 'AddClient',
        mixins: [MemberMixin],
        data: function () {
            return {
                   search: '',
                   loading: false,
                   working: false,
                   accessToken: '',
                   
                   user: [{billing: 0}],
                  
                    description: '',
                    
            }
        },
        methods: {
           
           
            async saveTicket() {
                this.working = true;
                
                    var formdata = { 
                        description: this.description,
                        
                    }; 

                    TicketService.saveTicket(this.accessToken, formdata)
                    .then(
                        () => {
                            this.working=false;
                            
                            this.$router.push("/tickets/my-tickets");
                        }
                    ).catch((error) => { 
                        console.log(error)
                        if (error.response.status == 409) {
                            alert(error.response.data.error)
                        } 
                        else{
                            alert(error.message)
                        } 
                        this.working=false;
                        
                    });
            },
           
            async getClientGroups() {
                
                UtilityService.getSimpleApiData(this.accessToken, "client_groups")
                .then(
                    (client_groups => {
                        this.$set(this, "client_groups", client_groups);
                       
                    }).bind(this)
                );
            },
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getClientGroups();
                
            });
        
        },
        watch: {
           
           
        },
    }
</script>