import axios from "axios"

let API_NAME = process.env.VUE_APP_API_NAME;

export default {
	async saveTicket(accessToken,  formdata) {
        let res = await axios.post(API_NAME + "/tickets" ,  formdata,
            {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
            
          });
          
        return res.data;
      },
  
  
}